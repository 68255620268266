import React from 'react';
import { Layout } from 'src/components/Layout';
import { Methods } from 'src/components/Methods/Methods';

const MethodsPage = () => (
    <Layout
        pageTitle="Methods"
        siteDescription="The primary way we help communities."
    >
        <Methods />
    </Layout>
);

export default MethodsPage;
